<template>
    <main class="ml-12">
        <div class="mt-12">
            <div class="font-normal text-sm flex flex-row gap-2">
                <img src="../../assets/svg/Path.svg">
                <a href="#">Account Details</a>
            </div>
        </div>
        <div class="flex flex-row  mt-8 gap-5">
            <div class="flex-column w-8/12">
                <div class=" bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow">
                    <div class="flex flex-row justify-between items-center">
                        <div class="flex-column">
                            <div class="flex flex-row items-center gap-4">
                                <img src="../../assets/svg/bank.svg">
                                <ul>
                                    <li class="font-normal text-xs">Actual Balance</li>
                                    <li class="font-bold text-2xl mt-2">NGN 12,000,000</li>
                                </ul>

                            </div>
                        </div>
                        <div class="flex-column mr-6 ml-auto">
                            <div class="flex flex-row">
                                <ul>
                                    <li class="font-normal text-xs">Bank: <span class="font-semibold text-xs">Rubies Microfinance Bank</span>
                                    </li>
                                    <li class="font-normal text-xs mt-3">Account: <span class="font-semibold text-xs">73029788</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div class="bg-accentLight h-8 w-8 px-2 py-2 rounded mt-auto mb-5">
                            <img src="../../assets/svg/copy.svg">
                        </div>
                    </div>
                    <div class="mt-3 bg-accentLight px-4 py-4 rounded-lg overflow-hidden">
                        <div class="flex flex-row justify-between">
                            <span class="font-medium text-sm text-primaryGray ml-8">Account Balance
                                <span class="font-normal text-black ml-3">NGN 9,000,000</span>
                            </span>
                            <span class="font-medium text-sm text-primaryGray ml-8 border-l">Pending Transaction
                                <span class="font-normal text-black ml-3">NGN 3,000,000</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-column w-4/12">
                <div class=" bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"></div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "AccountDetails"
    }
</script>

<style scoped>

</style>